const commonAPIs = {
  salesPubAPI: "https://salespub-test-bbgjlkdaiq-uc.a.run.app",
  translationAPI: "https://translation-api-bbgjlkdaiq-uc.a.run.app",
  supabaseURL: "https://igznoqfteaovnaqevjbf.supabase.co",
  supabaseAnonKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlnem5vcWZ0ZWFvdm5hcWV2amJmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjAzNjc3NTYsImV4cCI6MjAzNTk0Mzc1Nn0.0JGqBozV37p0nCKOakf-_pfmejv-db7Cwry5kycL2Co",
};

const dev = {
  baseURL: "http://localhost:3080/api",
  socketURL: "ws://localhost:3080/ws",
  landingPageUrl: "http://localhost:3000",
  translationAPI: "https://translation-api-test-bbgjlkdaiq-uc.a.run.app",
  salesPubAPI: "https://salespub-test-bbgjlkdaiq-uc.a.run.app",
  // salesPubAPI: "http://localhost:8000",
  // salesPubAPI: "https://salespub-prod-bbgjlkdaiq-uc.a.run.app",
  // salesPubAPI: "https://salespub-dev-923227513206.us-central1.run.app",
  // salesPubAPI: "http://localhost:8001",
};

const prod = {
  baseURL: "https://dashboard.xpub.ai/api",
  socketURL: "wss://dashboard.xpub.ai/ws",
  landingPageUrl: "https://dashboard.xpub.ai",
  salesPubAPI: "https://salespub-prod-bbgjlkdaiq-uc.a.run.app",
};

const staging = {
  baseURL: "https://staging.xpub.ai/api",
  socketURL: "wss://staging.xpub.ai/ws",
  landingPageUrl: "https://staging.xpub.ai",
};

const test = {
  baseURL: "https://test.xpub.ai/api",
  socketURL: "wss://test.xpub.ai/ws",
  landingPageUrl: "https://test.xpub.ai",
  salesPubAPI: "https://salespub-test-bbgjlkdaiq-uc.a.run.app",
};

const devTest = {
  baseURL: "https://dev.xpub.ai/api",
  socketURL: "wss://dev.xpub.ai/ws",
  landingPageUrl: "https://dev.xpub.ai",
  salesPubAPI: "https://salespub-dev-923227513206.us-central1.run.app",
};

let config;
switch (process.env.REACT_APP_ENV) {
  case "dev":
    config = dev;
    break;
  case "test":
    config = test;
    break;
  case "staging":
    config = staging;
    break;
  case "prod":
    config = prod;
    break;
  case "devTest":
    config = devTest;
    break;
  default:
    config = dev;
}

config = { ...commonAPIs, ...config };

export default config;
