import React from 'react';
import { Paper, styled } from '@mui/material';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PDFViewer from "./PDFViewer";

const ViewerPanel = styled(Paper)(({ theme }) => ({
  width: "calc(80% - 8px)",
  minWidth: "calc(80% - 8px)",
  maxWidth: "calc(80% - 8px)",
  height: "100%",
  border: "1px solid #ddd",
  ...theme.typography.body2,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const DocumentViewerPanel = ({ activeDocument, docs, tab, pdfViewerRef, lastPages }) => {
  if (
    activeDocument &&
    activeDocument.metadata.metadata.contentType === "application/pdf"
  ) {
    return (
      <ViewerPanel>
        {tab === "documents" && (
          <PDFViewer
            ref={pdfViewerRef}
            fileUrl={activeDocument.uri}
            initialPage={lastPages[activeDocument.uri] || 1}
          />
        )}
      </ViewerPanel>
    );
  } else {
    return (
      <ViewerPanel>
        <DocViewer
          documents={docs}
          activeDocument={activeDocument}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          config={{
            header: {
              disableHeader: true,
            },
            pdfVerticalScrollByDefault: true,
            className: "custom-doc-viewer",
          }}
          style={{
            height: "100%",
            width: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
        />
      </ViewerPanel>
    );
  }
};

export default DocumentViewerPanel;