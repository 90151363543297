import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Button,
  Modal,
  Paper,
  Tooltip,
  Badge,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useSelector, useDispatch } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import {
  addItem,
  reorderItems,
  deleteItemAndUpdate,
  setItems,
} from "../../reduxstore/itemsSlice";
import {
  addItemStatus,
  updateItemStatus,
} from "../../reduxstore/itemsStatusSlice";
import { api_external } from "../../store";
import env from "../../config";
import { v4 as uuidv4 } from "uuid";

function FacebookCircularProgress(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.grey[200],
          ...theme.applyStyles("dark", {
            color: theme.palette.grey[800],
          }),
        })}
        size={18}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: "#55828b",
          animationDuration: "550ms",
          position: "absolute",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            color: "#308fe8",
          }),
        })}
        size={18}
        thickness={5}
        {...props}
      />
    </Box>
  );
}
const EditableItem = React.memo(
  ({
    item,
    index,
    editMode,
    onItemClick,
    onDeleteItem,
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnd,
    isDraggedOver,
    isLoading,
    isError,
    isSelected,
    todoCount,
  }) => {
    const itemRef = useRef(null);

    return (
      <ListItem
        ref={itemRef}
        draggable={editMode}
        onDragStart={(e) => onDragStart(e, index)}
        onDragOver={(e) => onDragOver(e, index)}
        onDrop={(e) => onDrop(e, index)}
        onDragEnd={onDragEnd}
        onClick={() => onItemClick(item)}
        sx={{
          borderRadius: "8px",
          borderTopLeftRadius: isDraggedOver ? "0" : "8px",
          borderTopRightRadius: isDraggedOver ? "0" : "8px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          backgroundColor: isSelected ? "rgba(0, 0, 0, 0.06)" : "none",
          my: 1,
          cursor: editMode ? "move" : "pointer",
          borderTop: isDraggedOver ? "3px solid #719fa8" : "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
            flexGrow: 1,
            minWidth: 0,
          }}
        >
          {editMode && (
            <DragIndicatorIcon
              fontSize="small"
              sx={{ minWidth: "8px", paddingTop: "0px", paddingBottom: "0px" }}
            />
          )}
          <ListItemText
            primary={item.section_title}
            primaryTypographyProps={{
              sx: {
                fontSize: "0.875rem", // Sets the font size of the primary text
                lineHeight: 1.2,
                color: "#757575",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              },
            }}
            sx={{
              cursor: "pointer",
              flexGrow: 1,
              minWidth: 0,
            }}
          />
          {!editMode && !isLoading && !isError && todoCount > 0 && (
            <Badge
              title="TODOs"
              badgeContent={todoCount}
              sx={{
                "& .MuiBadge-badge": {
                  color: "#55828b",
                  border: "0.1rem solid #55828b",

                  // backgroundColor: '#8db2b9',
                  // color: "white",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& .css-ywgamk-MuiBadge-badge": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "0.1rem",
                },
              }}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
          {isLoading &&
            // <CircularProgress
            //   size={16}
            //   color="primary"
            //   sx={{ marginRight: "2px" }}
            // />
            FacebookCircularProgress()}
          {isError && !isLoading && (
            <ErrorRoundedIcon
              sx={{ marginRight: "2px", color: "red", fontSize: "16px" }}
            />
          )}
          {editMode && (
            <IconButton
              onClick={() => onDeleteItem(item)}
              size="small"
              sx={{
                paddingLeft: "2px",
                paddingRight: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </ListItem>
    );
  }
);

const DraftLeftPanelComponent = ({
  onItemClick,
  projectId,
  archiveIds,
  refreshItems,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const listRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newSectionDescription, setNewSectionDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const items = useSelector((state) => state.items);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);
  const [subsections, setSubsections] = useState([]);
  const handleDeleteItem = useCallback(
    async (itemToDelete) => {
      const originalItems = [...items];
      const updatedItems = items.filter(
        (item) => item.uuid !== itemToDelete.uuid
      );

      // Optimistically update the UI
      dispatch(setItems(updatedItems));

      try {
        await dispatch(
          deleteItemAndUpdate({ itemToDelete, projectId })
        ).unwrap();
      } catch (error) {
        console.error("Error deleting item:", error);
        // Revert the change if the operation fails
        dispatch(setItems(originalItems));
        setSnackbarMessage("Failed to delete item. Please try again.");
        setSnackbarOpen(true);
      }
    },
    [items, dispatch, projectId]
  );

  const handleReorderItems = useCallback(
    async (newOrder) => {
      const originalItems = [...items];

      // Optimistically update the UI
      dispatch(setItems(newOrder));

      try {
        await dispatch(reorderItems({ newOrder, projectId })).unwrap();
      } catch (error) {
        console.error("Error reordering items:", error);
        // Revert the change if the operation fails
        dispatch(setItems(originalItems));
        setSnackbarMessage("Failed to reorder items. Please try again.");
        setSnackbarOpen(true);
      }
    },
    [items, dispatch, projectId]
  );

  const handleAddItem = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewSectionTitle("");
    setNewSectionDescription("");
  };

  // Ensure that editMode is not changed during drag & drop
  const onDragStart = useCallback(
    (e, index) => {
      setDraggedItem(items[index]);
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", index);
    },
    [items]
  );

  const onDragOver = useCallback((e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  }, []);

  const onDragEnd = useCallback(() => {
    setDraggedItem(null);
    setDragOverIndex(null);
  }, []);

  const onDrop = useCallback(
    (e, droppedIndex) => {
      e.preventDefault();
      const draggedIndex = Number(e.dataTransfer.getData("text/plain"));

      if (draggedIndex === droppedIndex) return;

      const updatedItems = [...items];
      const [reorderedItem] = updatedItems.splice(draggedIndex, 1);
      updatedItems.splice(droppedIndex, 0, reorderedItem);

      handleReorderItems(updatedItems);
    },
    [items, handleReorderItems]
  );

  const handleSubsectionChange = (index, field, value) => {
    const updatedSubsections = [...subsections];
    updatedSubsections[index][field] = value;
    setSubsections(updatedSubsections);
  };

  const addSubsection = () => {
    setSubsections([...subsections, { title: "", description: "" }]);
  };

  const removeSubsection = (index) => {
    const updatedSubsections = [...subsections];
    updatedSubsections.splice(index, 1);
    setSubsections(updatedSubsections);
  };

  const handleGenerateNewSection = async () => {
    // Check for empty fields
    if (!newSectionTitle.trim() || !newSectionDescription.trim()) {
      setSnackbarMessage("Section title and description are required.");
      setSnackbarOpen(true);
      return;
    }

    // Check if any subsection has empty fields
    const hasEmptySubsection = subsections.some(
      (subsection) => !subsection.title.trim() || !subsection.description.trim()
    );
    if (hasEmptySubsection) {
      setSnackbarMessage("All subsection fields must be filled.");
      setSnackbarOpen(true);
      return;
    }

    handleCloseModal();
    setEditMode(false);
    const id = uuidv4();

    const newItem = {
      uuid: id,
      section_title: newSectionTitle,
      description: newSectionDescription,
    };

    // Optimistically add the new item
    dispatch(addItem(newItem));
    dispatch(addItemStatus({ uuid: id, isGenerating: true }));

    // Add Free Text to subsections
    const newSubsections = subsections.map((subsection) => ({
      ...subsection,
      type: "Free Text",
    }));

    try {
      const requestData = {
        project_id: projectId,
        section_id: id,
        section_title: newSectionTitle,
        section_desc: newSectionDescription,
        archive_ids: archiveIds,
        subsections: newSubsections,
      };

      await api_external.post(
        `${env.salesPubAPI}/generate-rfx-section-response`,
        requestData
      );
      dispatch(updateItemStatus({ uuid: id, isGenerating: false }));
      refreshItems(id, true);
    } catch (error) {
      console.error("Error generating section:", error);
      dispatch(setItems(items.filter((item) => item.uuid !== id)));
      setSnackbarMessage("Failed to generate section. Please try again.");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
          borderBottom: "1px solid #ddd",
          height: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Typography
            variant="sectionHeading"
            sx={{ marginRight: "8px", color: "black" }}
          >
            Sections
          </Typography>
        </Box>
        <Tooltip title="Edit project structure" arrow>
          <IconButton
            onClick={() => setEditMode(!editMode)}
            size="small"
            sx={{ alignSelf: "flex-start", padding: 0 }}
          >
            {editMode ? (
              <CancelIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <List ref={listRef} sx={{ flexGrow: 1, overflowY: "auto", padding: 0 }}>
        {items.map((item, index) => {
          const isLoading =
            itemsStatus.find((status) => status.uuid === item.uuid)
              ?.isGenerating || false;
          const isError =
            itemsStatus.find((status) => status.uuid === item.uuid)?.isError ||
            false;

          const todoCount = itemsStatus.find(
            (status) => status.uuid === item.uuid
          )?.todoCount;

          return (
            <EditableItem
              key={item.uuid}
              item={item}
              index={index}
              editMode={editMode}
              onItemClick={onItemClick}
              onDeleteItem={handleDeleteItem}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragEnd={onDragEnd}
              isDraggedOver={dragOverIndex === index}
              isLoading={isLoading}
              isError={isError}
              isSelected={item.uuid === selectedItem?.uuid}
              todoCount={todoCount}
            />
          );
        })}
      </List>
      {editMode && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddItem}
          fullWidth
          sx={{ mt: 2, textTransform: "none" }}
        >
          Add New Section
        </Button>
      )}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-section-modal"
        aria-describedby="modal-to-add-new-section"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxHeight: "80vh",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: "auto",
          }}
        >
          <Typography variant="sectionHeading" sx={{ color: "black" }}>
            Add New Section
          </Typography>
          {snackbarOpen && (
            <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 1 }}>
              <Typography
                variant="body2"
                color="error"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ErrorIcon sx={{ mr: 1 }} />
                {snackbarMessage}
              </Typography>
            </Box>
          )}
          <TextField
            label="Section title"
            value={newSectionTitle}
            onChange={(e) => setNewSectionTitle(e.target.value)}
            fullWidth
            required
            variant="outlined"
            margin="dense"
            inputProps={{ maxLength: 50 }} // Limit the title to 100 characters
          />
          <TextField
            label="Section Description"
            value={newSectionDescription}
            onChange={(e) => setNewSectionDescription(e.target.value)}
            fullWidth
            required
            variant="outlined"
            margin="dense"
            multiline
            minRows={3}
            inputProps={{ maxLength: 150 }} // Limit the description to 500 characters
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              mb: 0.5,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Typography variant="subtitle1">Subsections</Typography>
              <Tooltip title="You can add up to 4 subsections to your new section.">
                <ErrorOutlineIcon sx={{ color: "grey", fontSize: "1rem" }} />
              </Tooltip>
            </Box>
            {subsections.length < 4 && (
              <IconButton onClick={addSubsection} size="small">
                <AddCircleOutlineIcon />
              </IconButton>
            )}
          </Box>
          {subsections.map((subsection, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", gap: 1, mt: 0.5 }}
            >
              <TextField
                label="Title"
                value={subsection.title}
                onChange={(e) =>
                  handleSubsectionChange(index, "title", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{ flexGrow: 1, width: "30%" }}
                inputProps={{ maxLength: 50 }} // Limit the subsection title to 100 characters
              />
              <TextField
                label="Description"
                value={subsection.description}
                onChange={(e) =>
                  handleSubsectionChange(index, "description", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{ flexGrow: 3, width: "60%" }}
                inputProps={{ maxLength: 100 }} // Limit the subsection description to 300 characters
              />
              <IconButton
                onClick={() => removeSubsection(index)}
                size="small"
                sx={{ paddingLeft: 0, marginLeft: 0 }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          ))}

          <Button
            variant="contained"
            onClick={handleGenerateNewSection}
            sx={{
              textTransform: "none",
              mt: 1,
            }}
          >
            Generate
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default DraftLeftPanelComponent;
