import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  IconButton,
  TextField,
  Button,
  Box,
  TextareaAutosize,
  Radio,
  Fade,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "@cyntler/react-doc-viewer/dist/index.css";
import { api } from "../../store";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import CircularProgress from "@mui/material/CircularProgress";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PDFViewer from "./PDFViewer";

// Styled components (unchanged)
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  position: "relative",
}));

const NoDataText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.2,
  color: "#757575",
  wordBreak: "break-word",
  overflowWrap: "break-word",
}));

const ListItemPrimaryText = styled("span")(({ theme }) => ({
  fontSize: "0.875rem !important",
  lineHeight: 1.2,
}));

const ListItemSecondaryText = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.2,
  color: "#757575",
  wordBreak: "break-word",
  overflowWrap: "break-word",
}));

const RadioStyleCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  width: 16,
  height: 16,
  position: "relative",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "&::before": {
    content: '""',
    display: "block",
    width: 16,
    height: 16,
    border: "2px solid orange",
    borderRadius: "50%",
    position: "absolute",
    left: 0,
    top: 0,
    boxSizing: "border-box",
  },
  "&.Mui-checked": {
    "&::before": {
      borderColor: "#55828b",
    },
    "&::after": {
      content: '""',
      display: "block",
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "#55828b",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
}));

const SourceViewer = ({
  open,
  anchorEl,
  docs,
  data,
  onClose,
  placement,
  columnIndex,
}) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [boundingBox, setBoundingBox] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [uniquePages, setUniquePages] = useState([]);
  const pdfViewerRef = useRef(null);


  useEffect(() => {
    if (open && data && data.length > 0 && docs && docs.length > 0) {
      const pages = [...new Set(data.map((item) => item.page_idx))].sort(
        (a, b) => a - b
      );
      setUniquePages(pages);
      updateCurrentPage(0);
      setPdfUrl(docs[0].uri);
    }
  }, [open, data, docs]);

  const updateCurrentPage = (index) => {
    if (index >= 0 && index < uniquePages.length) {
      setCurrentPageIndex(index);
      const currentPage = uniquePages[index];
      console.log(currentPage);
      pdfViewerRef.current?.jumpPage(currentPage);
      const item = data.find((item) => item.page_idx === currentPage);
      if (item && item.bbox) {
        setBoundingBox(item.bbox);
      }
    }
  };

  const handleNextPage = () => {
    updateCurrentPage(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    updateCurrentPage(currentPageIndex - 1);
  };

  if (!open) return null;

  const getOffsetX = () => {
    if (columnIndex === 2) {
      return 330; // Adjust this value to move the Popper further left
    }
    return 8;
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [-10, getOffsetX()],
          },
        },
        {
          name: "flip",
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
            rootBoundary: "document",
            padding: 8,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              width: 470,
              height: 600,
              p: 2,
              boxShadow: 3,
              border: "1px solid #ccc",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  flexGrow={1}
                  position="relative"
                  sx={{ overflow: "hidden" }}
                >
                  {isLoading && (
                    <CircularProgress
                      style={{ position: "absolute", top: "50%", left: "50%" }}
                    />
                  )}
                  {pdfUrl && (
                    <PDFViewer
                      ref={pdfViewerRef}
                      fileUrl={pdfUrl}
                      initialPage={uniquePages[0]}
                      showToolbar={false}
                      style={{ width: "100%", height: "100%" }}
                      defaultScale={0.7}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <IconButton
                    onClick={handlePreviousPage}
                    disabled={currentPageIndex === 0}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography>
                    {`Source ${currentPageIndex + 1} / ${uniquePages.length}`}
                  </Typography>
                  <IconButton
                    onClick={handleNextPage}
                    disabled={currentPageIndex === uniquePages.length - 1}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const renderSectionOrChecklist = (
  title,
  data,
  onItemToggle = null,
  onSave,
  useRadio = false,
  onAdd,
  columnIndex
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(data || []);
  const [errors, setErrors] = useState([]);
  const [docs, setDocs] = useState([]);
  const [sourceViewerAnchorEl, setSourceViewerAnchorEl] = useState(null);
  const [isSourceViewerOpen, setIsSourceViewerOpen] = useState(false);
  const sourcesButtonRef = useRef(null);

  useEffect(() => {
    setEditedData(data || []);
  }, [data]);

  const handleEdit = (index, field, value) => {
    const newData = [...editedData];
    newData[index][field] = value;
    setEditedData(newData);
  };

  const handleAdd = () => {
    setEditedData([...editedData, { item: "", value: "" }]);
  };

  const handleSave = () => {
    const newErrors = editedData.map((item) => ({
      item: item.value && item.value.trim() && !item.item.trim(),
      value: item.item.trim() && item.value && !item.value.trim(),
    }));

    setErrors(newErrors);

    if (newErrors.some((error) => error.item || error.value)) {
      return;
    }

    onSave(
      title,
      editedData.filter((item) => item.item.trim() || item.value?.trim())
    );
    setIsEditing(false);
  };

  const renderActionButton = () => (
    <IconButton onClick={isEditing ? handleSave : () => setIsEditing(true)}>
      {isEditing ? (
        <CancelRoundedIcon sx={{ fontSize: "1rem" }} />
      ) : (
        <EditIcon sx={{ fontSize: "1rem", color: "darkgrey" }} />
      )}
    </IconButton>
  );

  const handleViewSource = async () => {
    if (data && data.length > 0) {
      const allFiles = data.flatMap((item) =>
        item.file_id ? [item.file_id] : []
      );
      const uniqueFiles = [...new Set(allFiles)];

      if (!isSourceViewerOpen) {
        try {
          const docs = await Promise.all(
            uniqueFiles.map(async (fileId) => {
              const signedResponse = await api.get(
                `file/getSignedURL/${fileId}`
              );
              return {
                uri: signedResponse.data.url,
              };
            })
          );
          setDocs(docs);
        } catch (error) {
          console.error("Error fetching signed URLs:", error);
        }
      }

      setSourceViewerAnchorEl(sourcesButtonRef.current);
      setIsSourceViewerOpen(!isSourceViewerOpen);
    }
  };

  const renderSourcesButton = () => (
    <IconButton onClick={handleViewSource} ref={sourcesButtonRef}>
      <FormatQuoteRoundedIcon sx={{ fontSize: "1rem", color: "darkgrey" }} />
    </IconButton>
  );

  const renderContent = () => {
    if (editedData.length === 0 && !isEditing) {
      return <NoDataText>No data available for {title}</NoDataText>;
    }
    const filteredData = data.filter(
      (item) => item.value !== null && item.value !== undefined
    );

    if (filteredData.length === 0 && !isEditing) {
      return <NoDataText>No data available for {title}</NoDataText>;
    }

    return (
      <>
        <List>
          {editedData.map((item, index) => (
            <ListItem
              key={index}
              style={{
                padding: onItemToggle ? "0 8px" : "8px",
                alignItems: "flex-start",
              }}
            >
              {onItemToggle &&
                (useRadio ? (
                  <RadioStyleCheckbox
                    edge="start"
                    checked={item.checked || false}
                    tabIndex={-1}
                    onChange={() => onItemToggle(title, index)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 20, color: "#55828b" },
                      margin: "9px 8px 8px 0",
                      padding: "0 8px",
                    }}
                  />
                ) : (
                  <Checkbox
                    edge="start"
                    checked={item.checked || false}
                    tabIndex={-1}
                    onChange={() => onItemToggle(title, index)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 20, color: "#55828b" },
                      padding: "4px 8px",
                    }}
                  />
                ))}

              <ListItemText
                primary={
                  isEditing ? (
                    <TextField
                      value={item.item}
                      onChange={(e) =>
                        handleEdit(index, "item", e.target.value)
                      }
                      fullWidth
                      variant="standard"
                      placeholder="Title"
                      helperText={
                        errors[index]?.item && "Title cannot be empty"
                      }
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "0.875rem",
                          // fontWeight: "bold",
                          padding: 0,
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "4px",
                          margin: "0 0 1px 0",
                          border: "2px solid lightgrey",
                          "&:hover, &.Mui-focused": {
                            border: "2px solid #719fa8",
                          },
                        },
                      }}
                    />
                  ) : (
                    item.item
                  )
                }
                secondary={
                  isEditing ? (
                    <TextField
                      value={item.value}
                      onChange={(e) =>
                        handleEdit(index, "value", e.target.value)
                      }
                      fullWidth
                      multiline
                      variant="standard"
                      placeholder="Description"
                      helperText={
                        errors[index]?.value && "Value cannot be empty"
                      }
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "0.875rem",
                          color: "#757575",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "2px solid lightgrey",
                          borderRadius: "4px",
                          "&:hover, &.Mui-focused": {
                            border: "2px solid #719fa8",
                          },
                        },
                        "& .MuiInputBase-input": {
                          padding: "1px 0px",
                        },
                      }}
                    />
                  ) : (
                    item.value
                  )
                }
                primaryTypographyProps={{
                  component: ListItemPrimaryText,
                }}
                secondaryTypographyProps={{
                  component: ListItemSecondaryText,
                }}
              />
            </ListItem>
          ))}
        </List>
        {isEditing && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAdd}
              variant="outlined"
              size="small"
            >
              Add Item
            </Button>
          </Box>
        )}
      </>
    );
  };

  const getSourceViewerPlacement = () => {
    if (columnIndex === 2) return "left-start";
    return "right-start";
  };

  return (
    <StyledPaper elevation={3}>
      <Typography variant="sectionHeading" sx={{ color: "black" }}>
        {title}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 1,
        }}
      >
        {/* {renderSourcesButton()} */}
        {renderActionButton()}
      </Box>

      <Divider sx={{ mt: 2, px: 2 }} />
      {renderContent()}
      <SourceViewer
        open={isSourceViewerOpen}
        anchorEl={sourceViewerAnchorEl}
        docs={docs}
        data={data}
        onClose={() => setIsSourceViewerOpen(false)}
        placement={getSourceViewerPlacement()}
        columnIndex={columnIndex}
      />
    </StyledPaper>
  );
};

// Main RFPChecklist component
const RFPChecklist = ({ RFPData, onRFPDataUpdate }) => {
  const [localRFPData, setLocalRFPData] = useState(RFPData || {});

  useEffect(() => {
    if (RFPData && JSON.stringify(RFPData) !== JSON.stringify(localRFPData)) {
      setLocalRFPData(RFPData);
    }
  }, [RFPData]);

  const handleItemToggle = useCallback(
    (sectionTitle, itemIndex) => {
      setLocalRFPData((prevData) => {
        const newData = { ...prevData };
        const sectionKey = sectionTitle.toLowerCase().replace(/ /g, "_");
        const section = newData[sectionKey];
        if (section && Array.isArray(section)) {
          section[itemIndex] = {
            ...section[itemIndex],
            checked: !section[itemIndex].checked,
          };
        }
        onRFPDataUpdate(newData);
        return newData;
      });
    },
    [onRFPDataUpdate]
  );

  const handleSave = (sectionTitle, newData) => {
    setLocalRFPData((prevData) => {
      const newLocalData = { ...prevData };
      const sectionKey = sectionTitle.toLowerCase().replace(/ /g, "_");
      newLocalData[sectionKey] = newData;
      onRFPDataUpdate(newLocalData);
      return newLocalData;
    });
  };

  const renderGridItem = (
    sections,
    size,
    useChecklist = false,
    columnIndex
  ) => (
    <Grid item xs={12} md={size}>
      {sections.map((section) =>
        renderSectionOrChecklist(
          section.title,
          localRFPData[section.key],
          useChecklist ? handleItemToggle : null,
          handleSave,
          section.isRadio,
          null,
          columnIndex
        )
      )}
    </Grid>
  );

  const sections = [
    { title: "Basic Details", key: "basic_details" },
    { title: "Contact Details", key: "contact_details" },
    { title: "Timelines", key: "rfp_project_timelines" },
    { title: "Budget & Payment Details", key: "budget_payment" },
  ];

  const checklistSection1 = [
    { title: "Scope of Work", key: "scope_of_work", isRadio: true },
    { title: "Evaluation Criteria", key: "evaluation_criteria" },
    { title: "Other Requirements", key: "other_requirements" },
    { title: "Warranty", key: "warranty" },
    { title: "Additional Notes", key: "additional_notes" },
  ];

  const checklistSection2 = [
    {
      title: "RFP Response Documents Checklist",
      key: "rfp_response_documents_checklist",
    },
    { title: "Project Deliverables", key: "project_deliverables" },
    {
      title: "Response Formatting Requirements",
      key: "response_formatting_requirements",
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      style={{
        padding: "0px 0.5rem 0px 0.5rem",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {renderGridItem(sections, 3)}
      {renderGridItem(checklistSection1, 5, true)}
      {/* {renderGridItem(sections.slice(4), 5)} */}
      {renderGridItem(checklistSection2, 4, true)}
      {/* {renderGridItem(sections.slice(0, 4), 3, false, 0)}
      {renderGridItem(sections.slice(4), 5, false, 1)} */}
      {/* {renderGridItem(checklistSections, 4, true, 2)} */}
    </Grid>
  );
};

export default RFPChecklist;
