import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";

import { createStore } from "@react-pdf-viewer/core";

const customNavigationPlugin = () => {
  const store = React.useMemo(() => createStore({}), []);

  return {
    install: (pluginFunctions) => {
      store.update("jumpToPage", pluginFunctions.jumpToPage);
    },
    jumpToPage: (pageIndex) => {
      const jump = store.get("jumpToPage");
      if (jump) {
        jump(pageIndex);
      }
    },
  };
};

const PDFViewer = forwardRef(
  ({ fileUrl, initialPage, showToolbar = true, defaultScale = 1.1 }, ref) => {
    const viewerRef = useRef(null);
    const currentPageRef = useRef(initialPage);

    // Create custom navigation plugin instance
    const customNavigationPluginInstance = customNavigationPlugin();
    const { jumpToPage } = customNavigationPluginInstance;

    useImperativeHandle(ref, () => ({
      getCurrentPage: () => currentPageRef.current,
      jumpPage: (pageIndex) => {
        console.log("Jumping to page:", pageIndex);
        jumpToPage(pageIndex);
      },
    }));

    const handlePageChange = (e) => {
      currentPageRef.current = e.currentPage;
    };

    const pageLayout = {
      transformSize: ({ size }) => ({
        height: size.height + 10,
        width: size.width + 10,
      }),
    };

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      Open: () => <></>,
      SwitchTheme: () => <></>,
    });

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "var(--border-radius)",
          margin: "0rem 0.3rem 0.3rem 0.3rem",
          height: "calc(100vh - 3.5rem)",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <div style={{ height: "100%" }}>
            {showToolbar && (
              <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            )}
            <div
              style={{
                height: showToolbar ? "calc(100% - 40px)" : "100%",
                overflow: "auto",
                borderRadius: "var(--border-radius)",
              }}
            >
              <Viewer
                ref={viewerRef}
                plugins={[
                  toolbarPluginInstance,
                  customNavigationPluginInstance,
                ]}
                fileUrl={fileUrl}
                defaultScale={defaultScale}
                pageLayout={pageLayout}
                onPageChange={handlePageChange}
                initialPage={initialPage}
              />
            </div>
          </div>
        </Worker>
      </div>
    );
  }
);

export default PDFViewer;

// const highlightPluginInstance = highlightPlugin({
//   renderHighlightTarget: (props) => (
//     <div
//       style={{
//         borderRadius: "var(--border-radius)",
//         padding: "0.5rem 1rem",
//         background: "#eee",
//         display: "flex",
//         gap: "0.5rem",
//         flexDirection: "column",
//         position: "absolute",
//         left: `${props.selectionRegion.left}%`,
//         top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
//         transform: "translate(0, 8px)",
//         zIndex: 1,
//         boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
//         fontSize: "var(--text-medium)",
//       }}
//     >
//       <button
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "0.5rem",
//           color: "var(--element-hover-color)",
//           fontSize: "var(--text-medium)",
//         }}
//         onClick={() => {
//           props.toggle();
//           onAnswer(props.selectedText);
//         }}
//       >
//         <QuestionAnswering />
//         Answer
//       </button>

//       <Divider />
//       <button
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "0.5rem",
//           color: "var(--element-hover-color)",
//           fontSize: "var(--text-medium)",
//         }}
//         onClick={() => {
//           props.toggle();
//           onVerify(props.selectedText);
//         }}
//       >
//         <Checkmark />
//         Verify
//       </button>
//     </div>
//   ),
// });
