import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setItems } from "../reduxstore/itemsSlice";
import { setSelectedItem } from "../reduxstore/selectedItemSlice";
import {
  initializeItemsStatus,
  updateItemStatus,
} from "../reduxstore/itemsStatusSlice";
import { useLocation } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Fab,
  Paper,
  Stack,
  Button,
  TextField,
  CircularProgress,
  SnackbarContent,
  Typography,
} from "@mui/material";
import { api, api_external } from "../store";
import "react-quill/dist/quill.snow.css";
import "./Editor.scss";
import "./RFPEditor.scss";
import env from "../config";
import { styled } from "@mui/material/styles";
import RFPChecklist from "../Components/rfp/RFPChecklist";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import "@cyntler/react-doc-viewer/dist/index.css";
import RFPChecklistSkeleton from "../Components/rfp/RFPCheckListSkeleton";
import DraftLeftPanelComponent from "../Components/rfp/EditableDraftPanelLeft";
import DraftSourcesPanelComponent from "../Components/rfp/DraftSourcesPanel";
import Snackbar from "@mui/material/Snackbar";
import ErrorIcon from "@mui/icons-material/Error";
import ExportModal from "../Components/rfp/ExportModal";
import { saveAs } from "file-saver";
import DraftEditorPanel from "../Components/rfp/DraftEditorPanel";
import ChatIcon from "@mui/icons-material/Chat";
import ChatWidget from "../Components/ChatWidget";
import DocumentLeftPanelComponent from "../Components/rfp/DocumentLeftPanelComponent";
import DocumentViewerComponent from "../Components/rfp/DocumentViewerComponent";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AnimatedChatFab from "../Components/AnimatedFab";

function RFPEditor() {
  const { state, search } = useLocation();
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [tab, setTab] = React.useState("overview");
  const [rfpCheckListData, setRFPCheckListData] = useState(null);
  const [rowData, setRows] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [isCheckListLoading, setIsChecklistLoading] = useState(true);
  const [activeDocument, setActiveDocument] = useState(null);
  const [docs, setDocs] = useState([]);
  const rteRef = useRef(null);
  const [readinessScore, setReadinessScore] = useState(0.0);

  const [rfpData, setRfpData] = useState(null);
  const dispatch = useDispatch();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(search);
  const projectId = queryParams.get("projectId") || state?.projectId;
  const projectName = queryParams.get("projectName") || state?.projectName;
  const [lastPages, setLastPages] = useState({});
  const pdfViewerRef = useRef(null);

  const [projectNameEditing, setProjectNameEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(projectName);
  const [projectExporting, setProjectExporting] = useState(false);
  const [exportErr, setExportError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const availableTagOptions = useMemo(() => {
    const options = [];
    options.push({ id: "RFX", label: "RFX File", enabled: false });
    options.push({ id: "forms", label: "Form", enabled: true });
    return options;
  }, []);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const countTodos = (text) => {
    const regex = /\[TODO:[^\[]*?\]/g;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  };

  const handleProjectNameChange = (event) => {
    setEditedProjectName(event.target.value);
  };

  const handleProjectNameSubmit = async () => {
    if (editedProjectName.trim() !== projectName) {
      try {
        await api.put("/project/updateProjectName", {
          projectId,
          projectName: editedProjectName.trim(),
        });
        // Update the URL with the new project name
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set("projectName", editedProjectName.trim());
        window.history.replaceState({}, "", newUrl.toString());
      } catch (error) {
        console.error("Error updating project name:", error);
        // Revert to the original name if there's an error
        setEditedProjectName(projectName);
      }
    }
    setProjectNameEditing(false);
  };

  const refreshProjectData = async (sectionUUID, isNew = false) => {
    try {
      const response = await api.get("/project/getProjectById", {
        params: { projectId },
      });
      let filteredItems;
      if (response.data?.project.rfx_response_sections) {
        filteredItems = response.data.project.rfx_response_sections.filter(
          (item) => item.type === "Free Text" && item.answer != null
        );

        dispatch(setItems(filteredItems));
        // for the section uuid, update the todo count
        if (sectionUUID != null && isNew) {
          const item = filteredItems.find((item) => item.uuid === sectionUUID);
          const newTodoCount = countTodos(
            Array.isArray(item.answer)
              ? item.answer[item.answer.length - 1]
              : item.answer || ""
          );
          dispatch(
            updateItemStatus({
              uuid: sectionUUID,
              todoCount: newTodoCount,
            })
          );
        }
      }
      return filteredItems;
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const initializeProjectData = async () => {
    try {
      const response = await api.get("/project/getProjectById", {
        params: { projectId },
      });
      if (response.data?.project.rfx_checklist) {
        setRFPCheckListData(response.data.project.rfx_checklist);
      }
      setIsChecklistLoading(false);

      if (response.data?.project.rfx_response_sections) {
        const filteredItems =
          response.data.project.rfx_response_sections.filter(
            (item) => item.type === "Free Text" && item.answer != null && item.answer != ""
          );

        dispatch(setItems(filteredItems));
        const maxScore = 5.5;
        const midPoint = Math.random() * (7 - 2) + 2;
        const steepness = 1.5;

        const score =
          maxScore /
          (1 + Math.exp(-steepness * (filteredItems.length - midPoint)));

        // Ensure the score is always less than 5.6
        const cappedScore = Math.min(score, 5.5);
        setReadinessScore(cappedScore.toFixed(1));

        dispatch(
          initializeItemsStatus(
            filteredItems.map((item) => ({
              uuid: item.uuid,
              todoCount: countTodos(
                Array.isArray(item.answer)
                  ? item.answer[item.answer.length - 1]
                  : item.answer || ""
              ),
              version: Array.isArray(item.answer)
                ? item.answer.length - 1
                : undefined,
            }))
          )
        );

        if (filteredItems.length > 0) {
          dispatch(
            setSelectedItem({
              uuid: filteredItems[0].uuid,
              section_title: filteredItems[0].section_title,
              answer: filteredItems[0].answer,
              sources: filteredItems[0].sources,
              requirements: filteredItems[0].requirements,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const fetchProjectFiles = async (activeDocument) => {
    try {
      const fileList = await api.get("file/getFiles", {
        params: { projectId: projectId },
        headers: { "Cache-Control": "no-cache" },
      });
      const files = fileList.data;
      setProjectFiles(files);

      // Create a map of existing docs for quick lookup
      const existingDocsMap = new Map(docs.map(doc => [doc.id, doc]));

      const updatedDocs = await Promise.all(
        files.map(async (file) => {
          // Check if we already have this file's information
          const existingDoc = existingDocsMap.get(file.id);
          if (existingDoc) {
            return existingDoc;
          }

          // If we don't have the file's information, fetch it
          const [signedResponse, metadataResponse] = await Promise.all([
            api.get(`file/getSignedURL/${file.id}`),
            api.post("/file/getMetadataForFile", { fileId: file.id })
          ]);

          return {
            uri: signedResponse.data.url,
            metadata: metadataResponse.data,
            id: file.id,
          };
        })
      );

      setDocs(updatedDocs);

      if (updatedDocs.length > 0) {
        if (activeDocument === null || activeDocument === undefined) {
          setActiveDocument(updatedDocs[0]);
        } else {
          // Ensure we're using the updated version of the active document
          const updatedActiveDoc = updatedDocs.find(doc => doc.id === activeDocument.id) || updatedDocs[0];
          setActiveDocument(updatedActiveDoc);
        }
      }
    } catch (err) {
      console.error("Error fetching project files:", err);
      console.error("Error response:", err?.response?.data?.message);
    }
  };

  useEffect(() => {
    initializeProjectData();
    fetchProjectFiles();
  }, [projectId]);

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setUserArchiveIDs(response.data.archives);
    } else setUserArchiveIDs([]);
    getArchives(response.data.archives);
  };

  const getArchives = async (archiveIds) => {
    const idsString = archiveIds
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");
    let response = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );
    const archives = response.data;
    if (archives.length > 0) {
      setRows(archives);
    }
  };

  useEffect(() => {
    getUserArchivesId();
  }, []);

  const handleTabChange = (event, newValue) => {
    if (
      tab === "documents" &&
      newValue !== "documents" &&
      pdfViewerRef.current !== null &&
      activeDocument
    ) {
      // Leaving the documents tab, save the current page for the active document
      setLastPages((prevPages) => ({
        ...prevPages,
        [activeDocument.uri]: pdfViewerRef.current.getCurrentPage(),
      }));
    }
    setTab(newValue);
  };

  const DocumentLeftPanel = styled(Paper)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "calc(20% - 16px)", // Subtracting half of the spacing
    minWidth: "calc(20% - 16px)",
    maxWidth: "calc(20% - 16px)",
    border: "1px solid #ddd",
    padding: theme.spacing(2),
    ...theme.typography.body2,
  }));

  const handleDocumentClick = async (doc) => {
    try {
      const matchingDoc = docs.find((d) => d.uri.includes(doc.id));
      setActiveDocument(matchingDoc);
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };

  const processProjectFile = async (filePath) => {
    try {
      const requestData = {
        project_id: projectId,
        file_id: filePath,

      };
      await api_external.post(`${env.salesPubAPI}/process-project-file`, requestData);
    } catch (error) {
      console.error("Error processing project file:", error);
      throw error;
    }
  };

  const handleItemClick = useCallback(
    (item) => {
      dispatch(
        setSelectedItem({
          uuid: item.uuid,
          section_title: item.section_title,
          answer: item.answer,
          sources: item.sources,
          requirements: item.requirements,
        })
      );
    },
    [dispatch]
  );

  const handleExport = async (filename, include_todos) => {
    setExportError(false);
    setProjectExporting(true);
    if (!projectId) {
      return;
    }

    try {
      const requestData = {
        project_id: projectId,
        include_todos: include_todos,
      };

      // Start the export process
      const response = await api_external.post(
        `${env.salesPubAPI}/export-rfp`,
        requestData,
        { responseType: "blob" }
      );

      const blob = response.data;
      saveAs(blob, filename);

      // Notify the service worker about the successful download
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "DOWNLOAD_COMPLETE",
          payload: { filename: filename },
        });
      }
    } catch (error) {
      console.error("Error during export:", error);
      setExportError(true);

      // Notify the service worker about the failed download
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "DOWNLOAD_FAILED",
          payload: { filename, error: error.message },
        });
      }
    } finally {
      setProjectExporting(false);
    }
  };

  const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "500",
    width: "8rem",
    minHeight: "3rem",
  }));

  const handleRFPDataUpdate = async (newData) => {
    setRfpData(newData);
    try {
      await api.put("/project/updateRFPChecklist", {
        projectId,
        rfx_checklist: newData,
      });
    } catch (error) {
      console.error("Error updating RFP Checklist:", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setExportError(false);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem 1rem",
          bgcolor: "background.paper",
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", color: "#333" }}>
          <TextField
            value={editedProjectName}
            onChange={handleProjectNameChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleProjectNameSubmit();
                e.preventDefault();
                e.target.blur(); // Unfocus the input field
              }
            }}
            onBlur={handleProjectNameSubmit}
            autoFocus={projectNameEditing}
            inputProps={{ maxLength: 100 }}
            title="Rename"
            sx={{
              "& .MuiInputBase-input": {
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "1.2rem",
                letterSpacing: "0.05em",
                width: "40rem",
                border: "none",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 0,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #44686f",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #719fa8",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box sx={{ textAlign: "right" }}>
            <Typography sx={{ fontSize: "0.85rem" }} color="text.secondary">
              Readiness Index
            </Typography>
            <Typography color="text.secondary" sx={{ fontSize: "0.7rem" }}>
              (scale of 10)
            </Typography>
          </Box>
          <Box
            sx={{
              position: "relative",
              width: 35,
              height: 35,
            }}
          >
            <CircularProgress
              variant="determinate"
              value={59}
              size={35}
              thickness={4}
              sx={{
                color: "#FFA500",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="primaryText"
                component="div"
                color="#FFA500"
                fontWeight="bold"
              >
                {readinessScore}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "0rem 0.3rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.10)",
            position: "relative",
            zIndex: 1000,
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="project tabs"
          >
            <AntTab
              value="overview"
              label="Overview"
              icon={<DashboardRoundedIcon />}
              iconPosition="start"
            />
            <AntTab
              value="draft"
              label="Draft"
              icon={<EditNoteRoundedIcon />}
              iconPosition="start"
            />
            <AntTab
              value="documents"
              label="Documents"
              icon={<FolderOpenRoundedIcon />}
              iconPosition="start"
            />
          </Tabs>
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="contained"
            startIcon={
              projectExporting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <IosShareRoundedIcon />
              )
            }
            sx={{
              textTransform: "none",
              visibility: tab === "draft" ? "visible" : "hidden",
              disabled: projectExporting ? true : false,
              marginRight: 2,
              height: "70%",
            }}
            disableRipple
            disabled={projectExporting}
          >
            Export
          </Button>
        </Box>

        <Box sx={{ flexGrow: 1, overflow: "auto", padding: "0.5rem" }}>
          {tab === "overview" &&
            (isCheckListLoading ? (
              <RFPChecklistSkeleton />
            ) : (
              <RFPChecklist
                RFPData={rfpCheckListData}
                onRFPDataUpdate={handleRFPDataUpdate}
              />
            ))}

          {tab === "draft" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                padding: "0rem 0.5rem",
              }}
            >
              <DocumentLeftPanel>
                <DraftLeftPanelComponent
                  onItemClick={handleItemClick}
                  projectId={projectId}
                  archiveIds={userArchiveIDs.map((item) => item.id)}
                  refreshItems={(uuid, isNew) =>
                    refreshProjectData(uuid, isNew)
                  }
                />
              </DocumentLeftPanel>
              <DraftEditorPanel
                projectId={projectId}
                userArchiveIDs={userArchiveIDs.map((item) => item.id)}
                refreshItems={(uuid, isNew) =>
                  refreshProjectData(uuid, isNew)
                }
                rowData={rowData}
              />

              <DraftSourcesPanelComponent
                projectId={projectId}
                userArchiveIDs={userArchiveIDs.map((item) => item.id)}
                refreshItems={(uuid, isNew) => refreshProjectData(uuid, isNew)}
                rowData={rowData}
              />
            </Stack>
          )}
          {tab === "documents" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                padding: "0rem 0.5rem",
              }}
            >
              <DocumentLeftPanelComponent
                items={projectFiles}
                onItemClick={handleDocumentClick}
                activeDocument={activeDocument}
                projectId={projectId}
                availableTagOptions={availableTagOptions}
                processProjectFile={processProjectFile}
                fetchProjectFiles={fetchProjectFiles}
              />
              <DocumentViewerComponent
                activeDocument={activeDocument}
                docs={docs}
                tab={tab}
                pdfViewerRef={pdfViewerRef}
                lastPages={lastPages}
              />
            </Stack>
          )}
        </Box>
      </Box>
      <Snackbar
        open={exportErr}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon style={{ marginRight: "8px" }} />
              Export failed. Please retry the operation or contact support.
            </span>
          }
        />
      </Snackbar>
      <ExportModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectName={projectName}
        onExport={(filename) => handleExport(filename)}
      />
      {isChatOpen && (
        <ChatWidget
          projectFiles={projectFiles}
          onClose={() => setIsChatOpen(false)}
          archiveIds={userArchiveIDs.map((item) => item.id)}
          archiveFiles={rowData}
          projectId={projectId}
        />
      )}
      <AnimatedChatFab
        isChatOpen={isChatOpen}
        toggleChat={toggleChat}
      />
    </Box>
  );
}

export default RFPEditor;
