import React from 'react';
import { Fab } from '@mui/material';
import { ChatRounded as ChatIcon, KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded';
import { keyframes } from '@emotion/react';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';


const flipOutAnimation = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
`;

const flipInAnimation = keyframes`
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
`;

const AnimatedChatFab = ({ isChatOpen, toggleChat }) => {
  return (
    <Fab
      color="primary"
      aria-label="chat"
      onClick={toggleChat}
      sx={{
        position: "absolute",
        bottom: 16,
        right: 16,
        zIndex: 1001,
        transition: 'all 0.3s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      }}
    >
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AutoAwesomeRoundedIcon
          sx={{
            position: 'absolute',
            backfaceVisibility: 'hidden',
            height: '1.7rem',
            width: '1.7rem',
            animation: isChatOpen ? `${flipOutAnimation} 0.3s forwards` : `${flipInAnimation} 0.3s forwards`,
          }}
        />
        <KeyboardArrowDownRoundedIcon
          sx={{
            position: 'absolute',
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            height: '1.7rem',
            width: '1.7rem',
            animation: isChatOpen ? `${flipInAnimation} 0.3s forwards` : `${flipOutAnimation} 0.3s forwards`,
          }}
        />
      </div>
    </Fab>
  );
};

export default AnimatedChatFab;