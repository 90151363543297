import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Typography,
  Box,
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AvatarGroup,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  assignUser,
  removeAssignment,
  selectSectionAssignments,
} from "../../reduxstore/userAssignmentSlice";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

const roles = ["Author", "Reviewer", "Approver"];

const UserAssignment = ({ sectionId, users }) => {
  const dispatch = useDispatch();
  const assignments = useSelector((state) =>
    selectSectionAssignments(state, sectionId)
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRole, setActiveRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [showRoles, setShowRoles] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const handleMainClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowRoles(true);
    setActiveRole(null);
  };

  const handleRoleClick = (role) => {
    setActiveRole(role);
    setShowRoles(false);
    setSelectedUser("");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRole(null);
    setSelectedUser("");
    setShowRoles(false);
  };

  const handleAssign = () => {
    const dueDateString = dueDate ? dueDate.toISOString() : null;
    dispatch(assignUser({ sectionId, role: activeRole, userId: selectedUser, dueDate: dueDateString }));
    handleClose();
  };

  const handleRemove = (role) => {
    dispatch(removeAssignment({ sectionId, role }));
  };

  const handleInvite = () => {
    // Placeholder for invite functionality
    console.log(`Inviting user for role: ${activeRole}`);
    // Here you would typically open a modal or navigate to an invite page
    // For now, we'll just close the popover
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-assignment-popover" : undefined;

  const getAvailableUsers = (role) => {
    return users.filter(
      (user) =>
        !Object.entries(assignments).some(
          ([assignedRole, assignedUserId]) =>
            assignedRole !== role && assignedUserId === user.id
        )
    );
  };

  const getInitials = (name) => {
    if (name === "" || name === undefined) return;
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const assignedUsers = roles
    .map((role) => {
      const userId = assignments[role.toLowerCase()]?.userId;
      const user = users.find((u) => u.id === userId);
      return user ? { ...user, role } : null;
    })
    .filter(Boolean);

  const allRolesAssigned = assignedUsers.length === roles.length;

  const minDate = useMemo(() => {
    if (activeRole === "reviewer") {
      return assignments.author?.dueDate ? dayjs(assignments.author.dueDate) : dayjs();
    } else if (activeRole === "approver") {
      return assignments.reviewer?.dueDate ? dayjs(assignments.reviewer.dueDate) : dayjs();
    }
    return dayjs();
  }, [activeRole, assignments]);

  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date).format("MMM D, YYYY"); // This will work with both Date objects and date strings
  };

  return (
    <Box>
      <AvatarGroup
        max={3}
        spacing="10"
        onClick={handleMainClick}
        sx={{ cursor: "pointer" }}
      >
        {assignedUsers.map((user, index) => (
          <Tooltip key={user.id} title={`${user.name} (${user.role})`} arrow>
            <Avatar
              sx={{
                width: 20,
                height: 20,
                fontSize: "0.7rem",
                bgcolor: `hsl(${index * 70}, 50%, 50%)`,
              }}
            >
              {getInitials(user.name)}
            </Avatar>
          </Tooltip>
        ))}
        {!allRolesAssigned && (
          <Avatar
            sx={{
              width: 20,
              height: 20,
            }}
          >
            <PersonAddAltRoundedIcon sx={{ fontSize: "1rem" }} />
          </Avatar>
        )}
      </AvatarGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: 300 }}>
          {showRoles ? (
            <>
              <Typography
                variant="sectionHeading"
                sx={{ marginRight: "8px", color: "black" }}
              >
                Users
              </Typography>
              <List>
                {roles.map((role) => (
                  <ListItem
                    key={role}
                    button
                    onClick={() => handleRoleClick(role.toLowerCase())}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor: "#0000008a",
                          width: 25,
                          height: 25,
                          fontSize: "0.875rem",
                        }}
                      >
                        <Person2RoundedIcon sx={{ fontSize: "1rem" }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={role}
                      // secondary={
                      //   assignments[role.toLowerCase()]
                      //     ? users.find(
                      //       (u) => u.id === assignments[role.toLowerCase()]
                      //     )?.name || "Unknown User"
                      //     : "Not assigned"
                      // }
                      secondary={
                        assignments[role.toLowerCase()] ? (
                          <>
                            {users.find(
                              (u) => u.id === assignments[role.toLowerCase()].userId
                            )?.name || "Unknown User"}
                            {assignments[role.toLowerCase()].dueDate && (
                              <span style={{ display: 'block', fontSize: '0.75rem', color: '#666' }}>
                                Due: {formatDate(assignments[role.toLowerCase()].dueDate)}
                              </span>
                            )}
                          </>
                        ) : (
                          "Not assigned"
                        )
                      }
                      primaryTypographyProps={{
                        sx: {
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        },
                      }}
                      secondaryTypographyProps={{
                        sx: {
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                          color: "#757575",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        },
                      }}
                    />
                    {assignments[role.toLowerCase()] && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="clear"
                          onClick={() => handleRemove(role.toLowerCase())}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            activeRole && (
              <>
                <Typography
                  variant="sectionHeading"
                  sx={{ marginRight: "8px", color: "black" }}
                >
                  Assign {activeRole}
                </Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="user-select-label">Select User</InputLabel>
                  <Select
                    labelId="user-select-label"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    label="Select User"
                  >
                    {getAvailableUsers(activeRole).map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ paddingTop: "0.3rem", mt: 2, width: "100%", '& .MuiTextField-root': { width: '100%' }, }}
                    >
                      <DatePicker
                        label="Due Date"
                        value={dueDate}
                        onChange={(date) => setDueDate(date)}
                        minDate={minDate}
                        sx={{ paddingTop: 0 }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleAssign}
                  disabled={!selectedUser}
                  sx={{ mt: 2 }}
                >
                  Assign User
                </Button>
                <Divider sx={{ my: 2 }}>or</Divider>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  onClick={handleInvite}
                  sx={{ mt: 2 }}
                >
                  Invite User
                </Button>
              </>
            )
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default UserAssignment;
