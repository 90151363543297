import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  ClickAwayListener
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import FileUploaderModal from '../FileUploaderModal';
import { api } from '../../store';

const DocumentLeftPanel = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "calc(20% - 16px)",
  minWidth: "calc(20% - 16px)",
  maxWidth: "calc(20% - 16px)",
  border: "1px solid #ddd",
  padding: theme.spacing(2),
  ...theme.typography.body2,
}));

const DocumentLeftPanelComponent = ({ items, onItemClick, activeDocument, projectId, availableTagOptions, processProjectFile, fetchProjectFiles }) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [renamingItem, setRenamingItem] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const renameInputRef = useRef(null);

  useEffect(() => {
    if (renamingItem) {
      const fileNameWithoutExtension = renamingItem.name.split('.').slice(0, -1).join('.');
      setNewFileName(fileNameWithoutExtension);
      if (renameInputRef.current) {
        renameInputRef.current.focus();
      }
    }
  }, [renamingItem]);

  const handleContextMenu = (event, item) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, item }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDeleteClick = () => {
    setItemToDelete(contextMenu.item);
    setDeleteConfirmOpen(true);
    handleClose();
  };

  const handleDelete = async () => {
    if (itemToDelete) {
      try {
        await api.delete(
          `file/deleteFile/?fileId=${itemToDelete.id}&ownerId=${projectId}`
        );
        await api.delete(
          `archives/deleteProjectFileVectors/?file_id=${itemToDelete.id}&namespace=projects`
        );
        fetchProjectFiles();
      } catch (error) {
        console.error("Error deleting file:", error);
        // Implement error handling (e.g., show an error message to the user)
      }
    }
    setDeleteConfirmOpen(false);
    setItemToDelete(null);
  };

  const handleRenameClick = () => {
    setRenamingItem(contextMenu.item);
    handleClose();
  };

  const handleRenameChange = (event) => {
    setNewFileName(event.target.value);
  };

  const handleRenameSubmit = async () => {
    console.log("renamingItem", renamingItem);
    console.log("newFileName", newFileName);

    // Check if we have a renamingItem and a newFileName
    if (renamingItem && newFileName) {
      // Prepare the new name with extension
      const fileNameParts = renamingItem.name.split('.');
      const extension = fileNameParts.length > 1 ? fileNameParts.pop() : '';
      const newName = extension ? `${newFileName}.${extension}` : newFileName;

      // Check if the new name is different from the current name
      if (newName !== renamingItem.name) {
        try {
          await api.put(
            `file/renameFile`,
            {
              fileId: renamingItem.id,
              existingName: renamingItem.name,
              newName: newName,
              ownerId: projectId
            }
          );
          fetchProjectFiles();
        } catch (error) {
          console.error("Error renaming file:", error);
          // Implement error handling (e.g., show an error message to the user)
        }
      } else {
        console.log("File name hasn't changed. Skipping rename operation.");
        // Optionally, you could notify the user that no change was made
      }
    }

    // Reset the renaming state regardless of whether a rename occurred
    setRenamingItem(null);
    setNewFileName('');
  };

  const handleRenameCancel = () => {
    setRenamingItem(null);
    setNewFileName('');
  };

  return (
    <DocumentLeftPanel>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
          borderBottom: "1px solid #ddd",
          height: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Typography
            variant="sectionHeading"
            sx={{ marginRight: "8px", color: "black" }}
          >
            Files
          </Typography>
        </Box>
        <Tooltip title="Add files" arrow>
          <IconButton
            onClick={() => setIsFileUploadModalOpen(true)}
            size="small"
            sx={{ alignSelf: "flex-start", padding: 0 }}
          >
            <NoteAddRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box style={{ height: "100%", overflowY: "auto" }}>
        <List>
          {items.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onItemClick(item)}
              onContextMenu={(event) => handleContextMenu(event, item)}
              sx={{
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                },
                my: 1,
                backgroundColor: activeDocument?.id === item.id ? "rgba(0, 0, 0, 0.06)" : "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {renamingItem && renamingItem.id === item.id ? (
                <ClickAwayListener onClickAway={handleRenameSubmit}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TextField
                      value={newFileName}
                      onChange={handleRenameChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRenameSubmit();
                        } else if (e.key === 'Escape') {
                          handleRenameCancel();
                        }
                      }}
                      inputRef={renameInputRef}
                      fullWidth
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="caption" color="textSecondary">
                      {`.${item.name.split('.').pop()}`}
                    </Typography>
                  </Box>
                </ClickAwayListener>
              ) : (
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "0.875rem",
                      lineHeight: 1.2,
                      color: "#757575",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                    marginBottom: "8px",
                    cursor: "pointer",
                    borderTop: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                  }}
                  primary={item.name}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Box>
      <FileUploaderModal
        modalHeading={"Add RFX project files"}
        isOpen={isFileUploadModalOpen}
        onClose={() => setIsFileUploadModalOpen(false)}
        allowedFileTypes={[".txt, .docx, .pdf"]}
        projectId={projectId}
        refreshFiles={fetchProjectFiles}
        allowAudio={false}
        displayTags={true}
        availableTagOptions={availableTagOptions}
        extractFileData={true}
        processFile={processProjectFile}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem sx={{ px: 4, fontSize: "0.875rem" }} onClick={handleDeleteClick}>Delete</MenuItem>
        <MenuItem sx={{ px: 4, fontSize: "0.875rem" }} onClick={handleRenameClick}>Rename</MenuItem>
      </Menu>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle><Typography variant="sectionHeading">Confirm Delete</Typography></DialogTitle>
        <DialogContent>
          <Typography variant="primaryText">Are you sure you want to delete this file?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DocumentLeftPanel>
  );
};

export default DocumentLeftPanelComponent;