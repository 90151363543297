import React, { useState, useRef, useMemo } from "react";
import {
  Paper,
} from "@mui/material";
import TipTapEditor from "../TipTapEditor";
import { styled } from "@mui/material/styles";
import { api } from "../../store";

const DraftAnswerPanel = styled(Paper)(({ theme }) => ({
  width: "calc(50% - 16px)", // Subtracting half of the spacing on each side
  minWidth: "calc(50% - 16px)",
  maxWidth: "calc(50% - 16px)",
  height: "100%",
  ...theme.typography.body2,
  overflow: "auto",
}));

const DraftEditorPanel = ({
  projectId,
  userArchiveIDs,
  refreshItems,
}) => {

  const rteRef = useRef(null);
  return (
    <DraftAnswerPanel>
      <TipTapEditor
        rteRef={rteRef}
        projectId={projectId}
        archiveIds={userArchiveIDs}
        refreshItems={(uuid, isNew) =>
          refreshItems(uuid, isNew)
        }
        showToolbar={true}
        displayItem="answer"
      />
    </DraftAnswerPanel>
  );
};

export default DraftEditorPanel;
