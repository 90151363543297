import React, { useState, useRef, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import TipTapEditor from "../TipTapEditor";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import BarChartIcon from "@mui/icons-material/BarChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { api_external } from "../../store";
import env from "../../config";
import { api } from "../../store";

const DraftSourcesPanel = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "calc(30% - 16px)",
  minWidth: "calc(30% - 16px)",
  maxWidth: "calc(30% - 16px)",
  height: "100%",
  border: "1px solid #ddd",
  ...theme.typography.body2,
  overflow: "auto",
}));

const buttonStyle = (isSelected) => ({
  flex: isSelected ? 2 : 1,
  textAlign: "center",
  textTransform: "none",
  fontSize: "1rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  transition: "all 0.3s ease",
  padding: "8px",
});

const iconStyle = (isSelected) => ({
  marginRight: isSelected ? "0.5rem" : "0",
});

const DraftSourcesPanelComponent = ({
  projectId,
  userArchiveIDs,
  fetchProjectData,
  rowData,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [view, setView] = useState("requirements");
  const reqsRef = useRef(null);
  const selectedItem = useSelector((state) => state.selectedItem);
  const [analysis, setAnalysis] = useState("");
  const [isLoadingAnalysis, setLoadingAnalysis] = useState(false);
  const itemsStatus = useSelector((state) => state.itemsStatus);

  const { sourcesToDisplay } = useMemo(() => {
    const statusVersion = itemsStatus.find(
      (status) => status.uuid === selectedItem?.uuid
    )?.version;
    let version;
    let sources;

    // console.log(statusVersion);

    if (statusVersion !== undefined) {
      sources = selectedItem?.sources?.[statusVersion];
    } else {
      sources = selectedItem?.sources;
    }

    return { sourcesToDisplay: sources };
  }, [selectedItem, itemsStatus]);

  function getFileNameFromPath(path, start_time) {
    // Extracts the file name from the path
    if (path !== undefined) {
      const foundItem = rowData.find((item) => item.id === path);
      return foundItem ? foundItem.name : path;
    } else return path;
  }

  const handleArchiveFile = async (path, start_time) => {
    try {
      let foundItem = rowData.find((item) => item.id === path);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contentType = response.data.fileType;

      if (contentType.includes("video")) {
        setShowVideoModal(true);
        setVideoUrl(response.data.url);
      } else if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSourceExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const renderToggleButton = (value, label, Icon) => {
    const isSelected = view === value;
    return (
      <Tooltip title={label}>
        <ToggleButton
          value={value}
          aria-label={value}
          sx={buttonStyle(isSelected)}
        >
          <Icon sx={iconStyle(isSelected)} />
          {isSelected && label}
        </ToggleButton>
      </Tooltip>
    );
  };

  const handleAnalyzeClick = async () => {
    setLoadingAnalysis(true);
    try {
      const requestData = {
        project_id: projectId,
        section_id: selectedItem.uuid,
      };
      const response = await api_external.post(
        `${env.salesPubAPI}/generate-rfx-section-analysis`,
        requestData
      );
      setAnalysis(response.data.analysis);
    } catch (error) {
      console.error("Error while generating analysis:", error);
    } finally {
      setLoadingAnalysis(false);
    }
  };

  return (
    <DraftSourcesPanel>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="view toggle"
        sx={{ width: "100%", height: "3rem" }}
      >
        {renderToggleButton(
          "requirements",
          "Requirements",
          ChecklistRoundedIcon
        )}
        {renderToggleButton("sources", "Sources", FormatQuoteRoundedIcon)}
        {renderToggleButton("analysis", "Analysis", BarChartIcon)}
      </ToggleButtonGroup>

      {view === "sources" && (
        <Box style={{ height: "100%", overflowY: "auto" }}>
          {sourcesToDisplay && sourcesToDisplay.length > 0 ? (
            [...sourcesToDisplay]
              .sort((a, b) => (a.uuid > b.uuid ? 1 : -1))
              .map((source, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === source.uuid}
                  onChange={handleSourceExpansion(source.uuid)}
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRoundedIcon />}
                    aria-controls={`panel-${source.uuid}-content`}
                    id={`panel-${source.uuid}-header`}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0 // Ensures that child elements respect the box size
                      }}
                    >
                      <Typography
                        variant="primaryText"
                        sx={{
                          display: 'block',
                        }}
                      >
                        [{source.uuid}] {source.metadata.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          fontSize: '0.75rem',
                          mt: 0.5,
                          display: 'block',
                          lineHeight: 1.2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap' // Ensure no wrapping for filenames
                        }}
                      >
                        {getFileNameFromPath(source.source_path)}
                      </Typography>
                    </Box>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="primaryText"
                      className="typography-content"
                      dangerouslySetInnerHTML={{
                        __html: source.extract,
                      }}
                    />
                    <br />
                    <br />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="primaryText">
                        Source:{" "}
                        {getFileNameFromPath(source.source_path) ||
                          "No path available"}
                      </Typography>
                      <LaunchRoundedIcon
                        onClick={() =>
                          handleArchiveFile(
                            source.source_path,
                            source.start_time
                          )
                        }
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
          ) : (
            <ListItem>
              <ListItemText primaryTypographyProps={{
                sx: {
                  fontSize: "0.875rem",
                  lineHeight: 1.2,
                  color: "#757575",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                },
              }} primary="No sources available." />
            </ListItem>
          )}
        </Box>
      )}
      {view === "requirements" &&
        (selectedItem.requirements ? (
          <Box style={{ height: "100%", overflowY: "auto" }}>
            <TipTapEditor
              rteRef={reqsRef}
              projectId={projectId}
              archiveIds={userArchiveIDs.map((item) => item.id)}
              refreshItems={fetchProjectData}
              showToolbar={false}
              displayItem="requirements"
            />
          </Box>
        ) : (
          <ListItem>
            <ListItemText primary="No requirements available." />
          </ListItem>
        ))}
      {view === "analysis" && (
        <Box style={{ height: "100%", overflowY: "auto" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleAnalyzeClick}
              startIcon={<AutoGraphIcon />}
              disabled={isLoadingAnalysis}
              sx={{ color: "black" }}
            >
              {isLoadingAnalysis ? "Analyzing..." : "Analyze"}
            </Button>
          </Box>
          {isLoadingAnalysis ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100px"
            >
              <CircularProgress />
            </Box>
          ) : analysis !== "" ? (
            <Box style={{ height: "90%", overflowY: "auto", padding: "1rem" }}>
              <span
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "400",
                }}
                dangerouslySetInnerHTML={{
                  __html: analysis,
                }}
              />
            </Box>
          ) : (
            <ListItem>
              <ListItemText
                primary="Click 'Analyze' to generate the section analysis."
                primaryTypographyProps={{
                  sx: {
                    fontSize: "0.875rem",
                    lineHeight: 1.2,
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                  },
                }}
              />
            </ListItem>
          )}
        </Box>
      )}
    </DraftSourcesPanel>
  );
};

export default DraftSourcesPanelComponent;
